#loader {
  width: 32em;
  height: 16em;
  position: fixed;
  overflow: hidden;
}

#loader::before {
  width: inherit;
  height: 0.1em;
  background-color: #0d47a1;
}

#loader span {
  position: fixed;
  width: 10px;
  height: 10px;
  top: 50%;
  background-color: #0d47a1;
  border-radius: 100%;
  left: -5%;
  animation: 2s linear infinite;
  transform-origin: 50% -3em;
  animation-name: run, rotating;
}

#loader span:nth-child(2) {
  animation-delay: 0.075s;
}
#loader span:nth-child(3) {
  animation-delay: 0.15s;
}
#loader span:nth-child(4) {
  animation-delay: 0.225s;
}
#loader span:nth-child(5) {
  animation-delay: 0.3s;
}
#loader span:nth-child(6) {
  animation-delay: 0.375s;
}
#loader span:nth-child(7) {
  animation-delay: 0.45s;
}
#loader span:nth-child(8) {
  animation-delay: 0.525s;
}
#loader span:nth-child(9) {
  animation-delay: 0.6s;
}
#loader span:nth-child(10) {
  animation-delay: 0.675s;
}
#loader span:nth-child(11) {
  animation-delay: 0.75s;
}
#loader span:nth-child(12) {
  animation-delay: 0.825s;
}

@keyframes run {
  0% {
    left: calc((100% - 5%) / 2);
  }
  10%,
  60% {
    left: calc((100% - 5%) / 2);
  }
  70%,
  100% {
    left: calc((100% - 5%) / 2);
  }
}

@keyframes rotating {
  0%,
  10% {
    transform: rotate(0deg);
  }
  60%,
  100% {
    transform: rotate(-1turn);
  }
}
